$primary-color: #55b971;
$background-color: #e0e0e0;
$disabled-color: hsl(0, 0%, 74%);
.steps-container {
    margin: 20px 70px ;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    .progress-container {
        margin: 16px 0;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        .step-element {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            height: 18px;
            width: 0px;
            overflow: visible;
            .step-circle {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 18px;
                width: 18px;
                border: 1px solid $disabled-color;
                border-radius: 9px;
                background-color: white;
                padding: 2px;
                position: relative;
                &.current {
                    border: 1px solid $primary-color;
                }
                &.completed {
                    border: 1px solid $primary-color;
                    .inner-circle {
                        height: 12px;
                        width: 12px;
                        border-radius: 6px;
                        background-color: $primary-color;
                    }
                }
            }
        }
    }
    .titles-container {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        .title-element {
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 0px;
            overflow: visible;
            .title-item {
                white-space: nowrap;
            }
        }
    }
    .content-container {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        .content-element {
            top: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 0px;
            overflow: visible;
            .content-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                white-space: nowrap;
                background-color: white;
                border-radius: 4px;   
                .title{
                    width:100%;
                    min-width: 134px;
                    height:30px;
                    padding-top: 9px;
                    padding-left: 8px;
                    border-top-left-radius:4px;
                    border-top-right-radius:4px;
                    background-color:$background-color;
                }
            }
        }
    }
}
.progress-bar-container {
    background-color: $background-color;
    height: 6px;
    width: 100%;
    .progress-bar-value {
        background-color: $primary-color;
        height: 100%;
        width: 0%;
    }
}
.content-items-container {
    overflow-y: auto;
    width: 100%;    
    height: 166px;
    text-overflow: ellipsis;
}
