.top-right {
  top: 12px;
  right: 12px;
  animation: toast-in-right 0.7s;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  animation: toast-in-right 0.7s;
}

.top-left {
  top: 12px;
  left: 12px;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 12px;
  left: 0px;
  animation: toast-in-left 0.7s;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    translate: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    translate: translateX(0);
  }
}
