@keyframes spinnerRotate {
  0% {
    transform: rotate(0);
    opacity: 1;
  }
  25% {
    transform: rotate(90deg);
    opacity: 0.25;
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.5;
  }
  75% {
    transform: rotate(270deg);
    opacity: 0.75;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}
