@font-face {
    font-family: "summernote";
    font-style: normal;
    font-weight: normal;
    src: url(summernote.eot);
    src: url(summernote.eot?#iefix) format("embedded-opentype"), url(summernote.woff) format("woff"), url(summernote.ttf) format("truetype")
}

[class^="note-icon-"]:before, [class*=" note-icon-"]:before {
    display: inline-block;
    font: normal normal normal 14px summernote;
    font-size: inherit;
    -webkit-font-smoothing: antialiased;
    text-decoration: inherit;
    text-rendering: auto;
    text-transform: none;
    vertical-align: middle;
    speak: none;
    -moz-osx-font-smoothing: grayscale
}

.note-icon-align-center:before {
    content: "\F101"
}

.note-icon-align-indent:before {
    content: "\F102"
}

.note-icon-align-justify:before {
    content: "\F103"
}

.note-icon-align-left:before {
    content: "\F104"
}

.note-icon-align-outdent:before {
    content: "\F105"
}

.note-icon-align-right:before {
    content: "\F106"
}

.note-icon-align:before {
    content: "\F107"
}

.note-icon-arrows-alt:before {
    content: "\F108"
}

.note-icon-bold:before {
    content: "\F109"
}

.note-icon-caret:before {
    content: "\F10A"
}

.note-icon-chain-broken:before {
    content: "\F10B"
}

.note-icon-circle:before {
    content: "\F10C"
}

.note-icon-close:before {
    content: "\F10D"
}

.note-icon-code:before {
    content: "\F10E"
}

.note-icon-eraser:before {
    content: "\F10F"
}

.note-icon-font:before {
    content: "\F110"
}

.note-icon-frame:before {
    content: "\F111"
}

.note-icon-italic:before {
    content: "\F112"
}

.note-icon-link:before {
    content: "\F113"
}

.note-icon-magic:before {
    content: "\F114"
}

.note-icon-menu-check:before {
    content: "\F115"
}

.note-icon-minus:before {
    content: "\F116"
}

.note-icon-orderedlist:before {
    content: "\F117"
}

.note-icon-pencil:before {
    content: "\F118"
}

.note-icon-addVariable:before {
    content: "\F118"
}

.note-icon-addTemplate:before {
    content: "\F118"
}

.note-icon-picture:before {
    content: "\F119"
}

.note-icon-question:before {
    content: "\F11A"
}

.note-icon-redo:before {
    content: "\F11B"
}

.note-icon-special-character:before {
    content: "\F11C"
}

.note-icon-square:before {
    content: "\F11D"
}

.note-icon-strikethrough:before {
    content: "\F11E"
}

.note-icon-subscript:before {
    content: "\F11F"
}

.note-icon-summernote:before {
    content: "\F120"
}

.note-icon-superscript:before {
    content: "\F121"
}

.note-icon-table:before {
    content: "\F122"
}

.note-icon-text-height:before {
    content: "\F123"
}

.note-icon-trash:before {
    content: "\F124"
}

.note-icon-underline:before {
    content: "\F125"
}

.note-icon-undo:before {
    content: "\F126"
}

.note-icon-unorderedlist:before {
    content: "\F127"
}

.note-icon-video:before {
    content: "\F128"
}

.note-editor {
    position: relative
}

.note-editor .note-dropzone {
    position: absolute;
    z-index: 100;
    display: none;
    color: #87cefa;
    background-color: white;
    opacity: .95
}

.note-editor .note-dropzone .note-dropzone-message {
    display: table-cell;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle
}

.note-editor .note-dropzone.hover {
    color: #098ddf
}

.note-editor.dragover .note-dropzone {
    display: table
}

.note-editor .note-editing-area {
    position: relative
}

.note-editor .note-editing-area .note-editable {
    outline: 0
}

.note-editor .note-editing-area .note-editable sup {
    vertical-align: super
}

.note-editor .note-editing-area .note-editable sub {
    vertical-align: sub
}

.note-editor.note-frame {
    border: 1px solid #e1e1e1
}

.note-editor.note-frame.codeview .note-editing-area .note-editable {
    display: none
}

.note-editor.note-frame.codeview .note-editing-area .note-codable {
    display: block
}

.note-editor.note-frame .note-editing-area {
    overflow: hidden
}

.note-editor.note-frame .note-editing-area .note-editable {
    padding: 10px;
    overflow: auto;
    color: #000;
    background-color: #fff
}

.note-editor.note-frame .note-editing-area .note-editable[contenteditable="false"] {
    background-color: #e5e5e5
}

.note-editor.note-frame .note-editing-area .note-codable {
    display: none;
    width: 100%;
    padding: 10px;
    margin-bottom: 0;
    font-family: Menlo, Monaco, monospace, sans-serif;
    font-size: 14px;
    color: #ccc;
    background-color: #222;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    resize: none
}

.note-editor.note-frame.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100% !important
}

.note-editor.note-frame.fullscreen .note-editable {
    background-color: white
}

.note-editor.note-frame.fullscreen .note-resizebar {
    display: none
}

.note-editor.note-frame .note-statusbar {
    background-color: #f5f5f5;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.note-editor.note-frame .note-statusbar .note-resizebar {
    width: 100%;
    height: 8px;
    padding-top: 1px;
    cursor: ns-resize
}

.note-editor.note-frame .note-statusbar .note-resizebar .note-icon-bar {
    width: 20px;
    margin: 1px auto;
    border-top: 1px solid #e1e1e1
}

.note-editor.note-frame .note-placeholder {
    padding: 10px
}

.note-popover.popover {
    max-width: none
}

.note-popover.popover .popover-content a {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle
}

.note-popover.popover .arrow {
    left: 20px !important
}

.note-popover .popover-content, .panel-heading.note-toolbar {
    padding: 0 0 5px 5px;
    margin: 0
}

.note-popover .popover-content > .btn-group, .panel-heading.note-toolbar > .btn-group {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 0
}

.note-popover .popover-content .btn-group .note-table, .panel-heading.note-toolbar .btn-group .note-table {
    min-width: 0;
    padding: 5px
}

.note-popover .popover-content .btn-group .note-table .note-dimension-picker, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker {
    font-size: 18px
}

.note-popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
    position: absolute !important;
    z-index: 3;
    width: 10em;
    height: 10em;
    cursor: pointer
}

.note-popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
    position: relative !important;
    z-index: 1;
    width: 5em;
    height: 5em;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat
}

.note-popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted {
    position: absolute !important;
    z-index: 2;
    width: 1em;
    height: 1em;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat
}

.note-popover .popover-content .note-style h1, .panel-heading.note-toolbar .note-style h1, .note-popover .popover-content .note-style h2, .panel-heading.note-toolbar .note-style h2, .note-popover .popover-content .note-style h3, .panel-heading.note-toolbar .note-style h3, .note-popover .popover-content .note-style h4, .panel-heading.note-toolbar .note-style h4, .note-popover .popover-content .note-style h5, .panel-heading.note-toolbar .note-style h5, .note-popover .popover-content .note-style h6, .panel-heading.note-toolbar .note-style h6, .note-popover .popover-content .note-style blockquote, .panel-heading.note-toolbar .note-style blockquote {
    margin: 0
}

.note-popover .popover-content .note-color .dropdown-toggle, .panel-heading.note-toolbar .note-color .dropdown-toggle {
    width: 20px;
    padding-left: 5px
}

.note-popover .popover-content .note-color .dropdown-menu, .panel-heading.note-toolbar .note-color .dropdown-menu {
    min-width: 340px
}

.note-popover .popover-content .note-color .dropdown-menu .btn-group, .panel-heading.note-toolbar .note-color .dropdown-menu .btn-group {
    margin: 0
}

.note-popover .popover-content .note-color .dropdown-menu .btn-group:first-child, .panel-heading.note-toolbar .note-color .dropdown-menu .btn-group:first-child {
    margin: 0 5px
}

.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title, .panel-heading.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
    margin: 2px 7px;
    font-size: 12px;
    text-align: center;
    border-bottom: 1px solid #eee
}

.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset, .panel-heading.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
    width: 100%;
    padding: 0 3px;
    margin: 3px;
    font-size: 11px;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}

.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-row, .panel-heading.note-toolbar .note-color .dropdown-menu .btn-group .note-color-row {
    height: 20px
}

.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset:hover, .panel-heading.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset:hover {
    background: #eee
}

.note-popover .popover-content .note-para .dropdown-menu, .panel-heading.note-toolbar .note-para .dropdown-menu {
    min-width: 216px;
    padding: 5px
}

.note-popover .popover-content .note-para .dropdown-menu > div:first-child, .panel-heading.note-toolbar .note-para .dropdown-menu > div:first-child {
    margin-right: 5px
}

.note-popover .popover-content .dropdown-menu, .panel-heading.note-toolbar .dropdown-menu {
    min-width: 90px
}

.note-popover .popover-content .dropdown-menu.right, .panel-heading.note-toolbar .dropdown-menu.right {
    right: 0;
    left: auto
}

.note-popover .popover-content .dropdown-menu.right::before, .panel-heading.note-toolbar .dropdown-menu.right::before {
    right: 9px;
    left: auto !important
}

.note-popover .popover-content .dropdown-menu.right::after, .panel-heading.note-toolbar .dropdown-menu.right::after {
    right: 10px;
    left: auto !important
}

.note-popover .popover-content .dropdown-menu.note-check li a i, .panel-heading.note-toolbar .dropdown-menu.note-check li a i {
    color: deepskyblue;
    visibility: hidden
}

.note-popover .popover-content .dropdown-menu.note-check li a.checked i, .panel-heading.note-toolbar .dropdown-menu.note-check li a.checked i {
    visibility: visible
}

.note-popover .popover-content .note-fontsize-10, .panel-heading.note-toolbar .note-fontsize-10 {
    font-size: 10px
}

.note-popover .popover-content .note-color-palette, .panel-heading.note-toolbar .note-color-palette {
    line-height: 1
}

.note-popover .popover-content .note-color-palette div .note-color-btn, .panel-heading.note-toolbar .note-color-palette div .note-color-btn {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    border: 1px solid #fff
}

.note-popover .popover-content .note-color-palette div .note-color-btn:hover, .panel-heading.note-toolbar .note-color-palette div .note-color-btn:hover {
    border: 1px solid #000
}

.note-dialog > div {
    display: none
}

.note-dialog .form-group {
    margin-right: 0;
    margin-left: 0
}

.note-dialog .note-modal-form {
    margin: 0
}

.note-dialog .note-image-dialog .note-dropzone {
    min-height: 100px;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 4;
    color: lightgray;
    text-align: center;
    border: 4px dashed lightgray
}

@-moz-document url-prefix() {
    .note-image-input {
        height: auto
    }
}

.note-placeholder {
    position: absolute;
    display: none;
    color: gray
}

.note-handle .note-control-selection {
    position: absolute;
    display: none;
    border: 1px solid black
}

.note-handle .note-control-selection > div {
    position: absolute
}

.note-handle .note-control-selection .note-control-selection-bg {
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-opacity: .3;
    -khtml-opacity: .3;
    -moz-opacity: .3;
    opacity: .3;
    -ms-filter: alpha(opacity=30);
    filter: alpha(opacity=30)
}

.note-handle .note-control-selection .note-control-handle {
    width: 7px;
    height: 7px;
    border: 1px solid black
}

.note-handle .note-control-selection .note-control-holder {
    width: 7px;
    height: 7px;
    border: 1px solid black
}

.note-handle .note-control-selection .note-control-sizing {
    width: 7px;
    height: 7px;
    background-color: white;
    border: 1px solid black
}

.note-handle .note-control-selection .note-control-nw {
    top: -5px;
    left: -5px;
    border-right: 0;
    border-bottom: 0
}

.note-handle .note-control-selection .note-control-ne {
    top: -5px;
    right: -5px;
    border-bottom: 0;
    border-left: none
}

.note-handle .note-control-selection .note-control-sw {
    bottom: -5px;
    left: -5px;
    border-top: 0;
    border-right: 0
}

.note-handle .note-control-selection .note-control-se {
    right: -5px;
    bottom: -5px;
    cursor: se-resize
}

.note-handle .note-control-selection .note-control-se.note-control-holder {
    cursor: default;
    border-top: 0;
    border-left: none
}

.note-handle .note-control-selection .note-control-selection-info {
    right: 0;
    bottom: 0;
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    color: white;
    background-color: black;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-opacity: .7;
    -khtml-opacity: .7;
    -moz-opacity: .7;
    opacity: .7;
    -ms-filter: alpha(opacity=70);
    filter: alpha(opacity=70)
}

.note-hint-popover {
    min-width: 100px;
    padding: 2px
}

.note-hint-popover .popover-content {
    max-height: 150px;
    padding: 3px;
    overflow: auto
}

.note-hint-popover .popover-content .note-hint-group .note-hint-item {
    display: block !important;
    padding: 3px
}

.note-hint-popover .popover-content .note-hint-group .note-hint-item.active, .note-hint-popover .popover-content .note-hint-group .note-hint-item:hover {
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1.4;
    color: white;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    background-color: #428bca;
    outline: 0
}

/* BASICS */

.CodeMirror {
    /* Set height, width, borders, and global font properties here */
    font-family: monospace;
    height: 300px;
    color: black;
}

/* PADDING */

.CodeMirror-lines {
    padding: 4px 0; /* Vertical padding around content */
}

.CodeMirror pre {
    padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
    background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */

.CodeMirror-gutters {
    border-right: 1px solid #ddd;
    background-color: #f7f7f7;
    white-space: nowrap;
}

.CodeMirror-linenumbers {
}

.CodeMirror-linenumber {
    padding: 0 3px 0 5px;
    min-width: 20px;
    text-align: right;
    color: #999;
    white-space: nowrap;
}

.CodeMirror-guttermarker {
    color: black;
}

.CodeMirror-guttermarker-subtle {
    color: #999;
}

/* CURSOR */

.CodeMirror-cursor {
    border-left: 1px solid black;
    border-right: none;
    width: 0;
}

/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
    border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
    width: auto;
    border: 0 !important;
    background: #7e7;
}

.cm-fat-cursor div.CodeMirror-cursors {
    z-index: 1;
}

.cm-animate-fat-cursor {
    width: auto;
    border: 0;
    -webkit-animation: blink 1.06s steps(1) infinite;
    -moz-animation: blink 1.06s steps(1) infinite;
    animation: blink 1.06s steps(1) infinite;
    background-color: #7e7;
}

@-moz-keyframes blink {
    0% {
    }
    50% {
        background-color: transparent;
    }
    100% {
    }
}

@-webkit-keyframes blink {
    0% {
    }
    50% {
        background-color: transparent;
    }
    100% {
    }
}

@keyframes blink {
    0% {
    }
    50% {
        background-color: transparent;
    }
    100% {
    }
}

/* Can style cursor different in overwrite (non-insert) mode */
.CodeMirror-overwrite .CodeMirror-cursor {
}

.cm-tab {
    display: inline-block;
    text-decoration: inherit;
}

.CodeMirror-rulers {
    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
    bottom: -20px;
    overflow: hidden;
}

.CodeMirror-ruler {
    border-left: 1px solid #ccc;
    top: 0;
    bottom: 0;
    position: absolute;
}

/* DEFAULT THEME */

.cm-s-default .cm-header {
    color: blue;
}

.cm-s-default .cm-quote {
    color: #090;
}

.cm-negative {
    color: #d44;
}

.cm-positive {
    color: #292;
}

.cm-header, .cm-strong {
    font-weight: bold;
}

.cm-em {
    font-style: italic;
}

.cm-link {
    text-decoration: underline;
}

.cm-strikethrough {
    text-decoration: line-through;
}

.cm-s-default .cm-keyword {
    color: #708;
}

.cm-s-default .cm-atom {
    color: #219;
}

.cm-s-default .cm-number {
    color: #164;
}

.cm-s-default .cm-def {
    color: #00f;
}

.cm-s-default .cm-variable,
.cm-s-default .cm-punctuation,
.cm-s-default .cm-property,
.cm-s-default .cm-operator {
}

.cm-s-default .cm-variable-2 {
    color: #05a;
}

.cm-s-default .cm-variable-3 {
    color: #085;
}

.cm-s-default .cm-comment {
    color: #a50;
}

.cm-s-default .cm-string {
    color: #a11;
}

.cm-s-default .cm-string-2 {
    color: #f50;
}

.cm-s-default .cm-meta {
    color: #555;
}

.cm-s-default .cm-qualifier {
    color: #555;
}

.cm-s-default .cm-builtin {
    color: #30a;
}

.cm-s-default .cm-bracket {
    color: #997;
}

.cm-s-default .cm-tag {
    color: #170;
}

.cm-s-default .cm-attribute {
    color: #00c;
}

.cm-s-default .cm-hr {
    color: #999;
}

.cm-s-default .cm-link {
    color: #00c;
}

.cm-s-default .cm-error {
    color: #f00;
}

.cm-invalidchar {
    color: #f00;
}

.CodeMirror-composing {
    border-bottom: 2px solid;
}

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {
    color: #0f0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
    color: #f22;
}

.CodeMirror-matchingtag {
    background: rgba(255, 150, 0, .3);
}

.CodeMirror-activeline-background {
    background: #e8f2ff;
}

/* STOP */

/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */

.CodeMirror {
    position: relative;
    overflow: hidden;
    background: white;
}

.CodeMirror-scroll {
    overflow: scroll !important; /* Things will break if this is overridden */
    /* 30px is the magic margin used to hide the element's real scrollbars */
    /* See overflow: hidden in .CodeMirror */
    margin-bottom: -30px;
    margin-right: -30px;
    padding-bottom: 30px;
    height: 100%;
    outline: none; /* Prevent dragging from highlighting the element */
    position: relative;
}

.CodeMirror-sizer {
    position: relative;
    border-right: 30px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
    position: absolute;
    z-index: 6;
    display: none;
}

.CodeMirror-vscrollbar {
    right: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: scroll;
}

.CodeMirror-hscrollbar {
    bottom: 0;
    left: 0;
    overflow-y: hidden;
    overflow-x: scroll;
}

.CodeMirror-scrollbar-filler {
    right: 0;
    bottom: 0;
}

.CodeMirror-gutter-filler {
    left: 0;
    bottom: 0;
}

.CodeMirror-gutters {
    position: absolute;
    left: 0;
    top: 0;
    min-height: 100%;
    z-index: 3;
}

.CodeMirror-gutter {
    white-space: normal;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: -30px;
}

.CodeMirror-gutter-wrapper {
    position: absolute;
    z-index: 4;
    background: none !important;
    border: none !important;
}

.CodeMirror-gutter-background {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 4;
}

.CodeMirror-gutter-elt {
    position: absolute;
    cursor: default;
    z-index: 4;
}

.CodeMirror-gutter-wrapper ::selection {
    background-color: transparent
}

.CodeMirror-gutter-wrapper ::-moz-selection {
    background-color: transparent
}

.CodeMirror-lines {
    cursor: text;
    min-height: 1px; /* prevents collapsing before first draw */
}

.CodeMirror pre {
    /* Reset some styles that the rest of the page might have set */
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-width: 0;
    background: transparent;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    white-space: pre;
    word-wrap: normal;
    line-height: inherit;
    color: inherit;
    z-index: 2;
    position: relative;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-variant-ligatures: contextual;
    font-variant-ligatures: contextual;
}

.CodeMirror-wrap pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: normal;
}

.CodeMirror-linebackground {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.CodeMirror-linewidget {
    position: relative;
    z-index: 2;
    overflow: auto;
}

.CodeMirror-widget {
}

.CodeMirror-rtl pre {
    direction: rtl;
}

.CodeMirror-code {
    outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.CodeMirror-measure {
    position: absolute;
    width: 100%;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.CodeMirror-cursor {
    position: absolute;
    pointer-events: none;
}

.CodeMirror-measure pre {
    position: static;
}

div.CodeMirror-cursors {
    visibility: hidden;
    position: relative;
    z-index: 3;
}

div.CodeMirror-dragcursors {
    visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
    visibility: visible;
}

.CodeMirror-selected {
    background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
    background: #d7d4f0;
}

.CodeMirror-crosshair {
    cursor: crosshair;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
    background: #d7d4f0;
}

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
    background: #d7d4f0;
}

.cm-searching {
    background: #ffa;
    background: rgba(255, 255, 0, .4);
}

/* Used to force a border model for a node */
.cm-force-border {
    padding-right: .1px;
}

@media print {
    /* Hide the cursor when printing */
    .CodeMirror div.CodeMirror-cursors {
        visibility: hidden;
    }
}

/* See issue #2901 */
.cm-tab-wrap-hack:after {
    content: '';
}

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
    background: none;
}
