   /* Hide scrollbar for Chrome, Safari and Opera */
.hide-horizontal-scroll-indicator::-webkit-scrollbar {
height: 0;
}

.hide-vertical-scroll-indicator::-webkit-scrollbar {
width: 0;
}

/* ---hide horizontal scroll indicator-- */
.hide-horizontal-scroll-indicator {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;     /* Firefox */
}
/* ---hide vertical scroll indicator-- */
.hide-vertical-scroll-indicator {
-ms-overflow-style: none;
scrollbar-width: none;
}