/* ----React Slider---- */
#react-slider {
  -webkit-appearance: none;
  background-color: transparent;
}
#react-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1px;
  color: 'pink';
}
#react-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
}

#react-slider-button-container {
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
}

#react-slider-button {
  background-color: #fff;
  clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 50% 100%, 0 50%);
  box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.28);
}
